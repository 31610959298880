<template>
    <div>
        <div class="container header-fixed">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div v-if="fetchingData">
                        <div class="d-block my-5 px-6 py-6 text-center rounded animate-load-1" style="background-color:#dee4ec;">
                            <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="120" class="mx-auto mb-4">
                            <p>Sedang mengambil data. Mohon tunggu...</p>
                        </div>
                    </div>
                    <div v-if="!detailData.status && !fetchingData">
                        <div class="d-block my-5 px-6 py-6 text-center rounded bg-light">
                            <img src="https://scolacdn.com/frontend/argonne-img/owly-loading.png" width="120" class="mx-auto mb-4">
                            <p class="m-0 d-block" style="opacity:.5;">Tidak ada data. Mohon untuk mengulang kembali halaman ini.</p>
                        </div>
                    </div>
                    <div v-if="detailData.status && !fetchingData">
                        <div class="d-block pt-md-5 pt-3 pb-md-5 mb-5 border-bottom">
                            <div class="d-flex align-items-center mb-4 pb-3 border-bottom">
                                <a href="javascript:history.back()" class="mr-md-4 mr-3"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                                <h4 class="m-0">Pengumuman</h4>
                            </div>
                            <h1 class="mb-3">{{detailData.data.title}}</h1>
                            <div class="d-flex align-items-center">
                                <div class="mr-4">
                                    <span class="fe fe-calendar mr-2"></span>{{detailData.data.date}}
                                </div>
                                <div class="mr-3">
                                    <span class="fe fe-user mr-2"></span>{{detailData.data.author}}
                                </div>
                            </div>
                        </div>
                        <div class="d-block pb-5 mb-4 block-content-article" v-html="detailData.data.description"></div>
                        <!-- <markdown-it-vue class="d-block pb-5 mb-4 block-content-article" :content="detailData.data.description" />                         -->
                    </div>

                    <div class="d-block">
                        <div class="d-block font-weight-bold pb-3 border-bottom mb-3" v-if="detailData.related_data.length>0 && !fetchingData">
                            Pengumuman Lainnya
                        </div>

                        <div v-if="fetchingData" class="row">
                            <div class="col-md-4 mb-md-4 mb-3 col-load">
                                <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                    <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="flex-grow-1 mb-4">
                                            <div class="skeleton-bar">
                                                <div class="sb-lg mb-2"></div>
                                                <div class="sb-md mb-2"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-100 sb-thin"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-60 sb-thin"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-md-4 mb-3 col-load">
                                <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                    <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="flex-grow-1 mb-4">
                                            <div class="skeleton-bar">
                                                <div class="sb-lg mb-2"></div>
                                                <div class="sb-md mb-2"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-100 sb-thin"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-60 sb-thin"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-md-4 mb-3 col-load">
                                <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                    <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="flex-grow-1 mb-4">
                                            <div class="skeleton-bar">
                                                <div class="sb-lg mb-2"></div>
                                                <div class="sb-md mb-2"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-100 sb-thin"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-60 sb-thin"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="detailData.related_data.length>0 && !fetchingData" class="row mb-5">
                            <div v-for="(item, index) in detailData.related_data" :key="index" class="col-md-4 mb-3">
                                <div v-on:click="redirectPage(item.id)" class="d-block pb-3 border-bottom h-100 link-btn-white" style="cursor:pointer !important">
                                    <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="flex-grow-1">
                                            <div class="d-flex align-items-start mb-4">
                                                <div class="icon-circle icon-circle-primary-light mr-3 flex-shrink-0">
                                                    <span class="fe fe-bell"></span>
                                                </div>
                                                <h3 class="m-0 text-dark">{{item.title}}</h3>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="text-dark small"><span class="fe fe-calendar mr-2"></span>{{item.date}}</div>
                                            <div class="text-dark small"><span class="fe fe-user mr-2"></span>{{item.author}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    export default {
        name: 'Detail',
        components: {

        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Program Detail'
        },
        data() {
            return {
                detailData: {
                    related_data: []
                },
                fetchingData: true,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                this.fetchingData = true;
                let uri = this.$apiconfig + 'website/blog/detail';

                this.$http.get(uri, {
                    params: {
                        type: 'announcement',
                        id: this.$route.params.id
                    }
                }).then(res => {
                    this.detailData = res.data;
                    this.fetchingData = false;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            },
            redirectPage: function(page_id) {
                this.$router.push({ name: 'pengumuman_detail', params: { id: page_id } });
                this.getData();
            }
        }
    }
</script>